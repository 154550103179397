import React from "react"
import { graphql } from 'gatsby'
import {
	mapEdgesToNodes,
	filterOutDocsWithoutSlugs,
	filterOutDocsPublishedInTheFuture
  } from '../lib/helpers'
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner"
import ProductSlider from "../components/ProductSlider/ProductSlider"
import BlogSlider from "../components/BlogSlider/BlogSlider"

export const bannerQuery = graphql`
    fragment SanityImage on SanityImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
    }

    query IndexPageQuery {
        sanityPage(slug: {current: {eq: "home"}}) {
          callout {
            title
            calloutCopy
            backgroundColor
            buttonText
            buttonLink
            buttonColor
          }
          pageGrid {
            title
            body
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          pageBanner {
            title
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
      }
    }
`

const IndexPage = props => {
  const { data } = props
  
  const postNodes = (data || {}).posts
  ? mapEdgesToNodes(data.posts)
    .filter(filterOutDocsWithoutSlugs)
    .filter(filterOutDocsPublishedInTheFuture)
  : []
	
	return (
		<Layout>
			<SEO title="Home" />
			<Banner 
				heading={ data.sanityPage.pageBanner.title }
				image={ data.sanityPage.pageBanner.image.asset.fluid }
				bannerAlt="banner--lg"
			/>
      <section className="introgrid">
        <div className="introgrid__row">
            <div className="introgrid__copy">
                <h3 className="introgrid__title">
                  Life
                </h3>
                <p>
                After fighting for the freedom of my clients for over two decades, I was able to build my law practice sufficiently to allow me the freedom to start making wine with my friends in Sonoma County. After practicing my skills up North, I began a small wine co-op in Southern California with a few good friends. After several years as a garagiste, my beautiful wife Jennifer gave me the freedom to go forward and make wine commercially.
                </p>
            </div>
            <div className="introgrid__img">
                <img src="https://cdn.sanity.io/images/0v4rxpqk/production/cb1662af1ec9badeff136c714d96337ee60376b0-750x500.jpg?w=1000&h=1000&fit=max" alt="" />
            </div>
        </div>   
        <div className="introgrid__row">
            <div className="introgrid__copy">
                <h3 className="introgrid__title">
                  Liberty
                </h3>
                <p>
                Our 2016 Debut Wines: Tempranillo, Syrah and Grenache Blanc. All three varietals are local, harvested from Estelle Vineyard in Santa Ynez, California. Estelle vineyard is sustainably grown and managed by grape grower extraordinaire, John Belfy, from Santa Maria, California. John has been planting and managing vineyards for decades and may be best known for his work at the acclaimed Tierra Alta Vineyard and earlier for his Camp 4 Vineyard both in Santa Ynez.                </p>
            </div>
            <div className="introgrid__img">
                <img src="https://cdn.sanity.io/images/0v4rxpqk/production/c0aef6aa9b3543e44fc0d0f84a51793a16cd0f7e-750x500.jpg?w=1000&h=1000&fit=max" alt="" />
            </div>
        </div>  
        <div className="introgrid__row">
            <div className="introgrid__copy">
                <h3 className="introgrid__title">
                  Pursuit of Happiness
                </h3>
                <p>
                Life is short. Eat good food, drink good wine, and most importantly, be good to your fellow man. Do something nice for someone else every day. Do something for yourself every day too. Buy flowers for your wife, just because. Call your parents, your children or your significant other and tell them you love them. Take your happiness seriously. Take your wine seriously too. Drink Freedom Wine, just not too much.

</p>
            </div>
            <div className="introgrid__img">
                <img src="https://cdn.sanity.io/images/0v4rxpqk/production/ed04cad71725dc3513a208404d4033904cd292a7-750x500.jpg?w=1000&h=1000&fit=max" alt="" />
            </div>
        </div>  
      </section>
      {/* <Callout
        heading={ data.sanityPage.callout.title }
        copy={ data.sanityPage.callout.calloutCopy }
        bgColor={ data.sanityPage.callout.backgroundColor }
        buttonCopy={ data.sanityPage.callout.buttonText }
        buttonStyle={ data.sanityPage.callout.buttonColor }
        buttonURL={ data.sanityPage.callout.buttonLink }
      /> */}
			<ProductSlider />	
      {/* <CalloutLocal /> */}

      {postNodes && (
        <BlogSlider
          title='Latest Blog Posts'
          node={ postNodes }
        />
      )}

		</Layout>
	  )
}

export default IndexPage;
