
import React from 'react'
import BlogPostPreview from './blog-post-preview'
import Slider from "react-slick"

function BlogPostSlides (props) {


    const settings = {
        dots: false,
        infinite: true,
        pauseOnHover: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

  return (
    <Slider {...settings}>
        {props.nodes &&
          props.nodes.map(node => (
            <div key={node.id}>
                <BlogPostPreview {...node} />
            </div>
          ))}
     </Slider>
  )
}

BlogPostSlides.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostSlides
