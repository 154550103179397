import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ProductsItem from '../ProductsListingItem/ProductsListingItem'
import Slider from "react-slick";

const PRODUCTS_SLIDER_QUERY = graphql`
  query ProductsListingQuery {
    products: allSanityProduct(sort: {
        fields: title, 
        order: DESC
        }, limit: 8) {
        edges {
          node {
            id
            title
            defaultPrice
            slug {
                current
            }
            mainImage {
                asset {
                    fixed(height: 294, width: 235) {
                        ...GatsbySanityImageFixed
                    }
                }
            }
          }
        }
      }
  }
`

const ProductsSlider = () => {

    const settings = {
        dots: false,
        infinite: true,
        pauseOnHover: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <div className="products__listing">
            <h2 className="gen-heading centered">Our Latest Releases</h2>
                <StaticQuery
                    query={PRODUCTS_SLIDER_QUERY}
                    render={({ products }) => {
                    return (
                        <div className="products__slider slider">
                            <Slider {...settings}>
                                {products.edges.map(({ node: product }) => (
                                    <ProductsItem key={product.id} product={product} />
                                ))}
                            </Slider>
                        </div>
                    )
                    }}
                />
        </div>
    )
}

export default ProductsSlider
