import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {mapEdgesToNodes} from '../../lib/helpers'
import BlogPostSlides from '../BlogPostSlides'
const BlogSlider = (props) => {

    const data = useStaticQuery(graphql`
    {
        posts: allSanityPost(
            sort: { fields: [publishedAt], order: DESC }
            filter: { slug: { current: { ne: null } } }
            ) {
            edges {
              node {
                id
                mainImage {
                  ...SanityImage
                }
                title
                _rawExcerpt
                slug {
                  current
                }
              }
            }
          }
      }
    `);

    const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

    return(
        <div className="blog__posts">
            <h2 className="gen-heading centered">{ props.title }</h2>
            <div className="blog__slider">
                {postNodes && postNodes.length > 0 && <BlogPostSlides nodes={postNodes} />}
            </div>
        </div>
    );
}

export default BlogSlider;